<template>
  <v-dialog :value='show' persistent  max-width='500px' scrollable>
    <v-card :disabled='loading' :loading='loading'>
      <v-card-title class='primary white--text py-2'>
        Merge Customers
      </v-card-title>
      <v-card-text style='max-height:500px;' class=''>
        <v-row class="mt-2">
          <v-col cols=12 class="secondary--text">
            Customer ID can be found in the address bar of a customer card.<br />
            <span class="font-italic"><span class="font-weight-bold">eg.</span> https://my.mountglass.com.au/dash/crm/<span class="primary--text">abc123xyz</span></span>
          </v-col>
          <v-col cols=12>
            <v-text-field outlined dense hide-details label="Customer ID" @change="fetchCustomer1" v-model="c1" />
            <div class="text-subtitle-2 text-center mt-1" v-if="customer_1">{{customer_1.name}}</div>
          </v-col>
          <v-col cols=12>
            <v-text-field outlined dense hide-details label="Customer ID" @change="fetchCustomer2" v-model="c2" />
            <div class="text-subtitle-2 text-center mt-1" v-if="customer_2">{{customer_2.name}}</div>
          </v-col>
          <v-col cols=12>
            <v-text-field outlined dense hide-details label="New Customer Name" v-model="name" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class='secondary' @click='show=false'>Cancel</v-btn>
        <v-btn class='primary' @click='merge' :disabled="!valid">Merge</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>

<script>
import { /* mapGetters, */  mapActions } from 'vuex';
export default {
  name: 'CustomerMerge',
  components: {},
  props: [],
  data(){
    return {
      show: false,
      loading: false,
      c1: null,
      c2: null,
      customer_1: null,
      customer_2: null,
      name: null,
    }
  },
  computed: {
    valid(){
      if(this.customer_1 == null) return false;
      if(this.customer_2 == null) return false;
      if(!this.name?.length) return false;
      if(this.customer_1.xid == this.customer_2.xid) return false;
      return true;
    },
  },
  methods: {
    ...mapActions("UI", ["CONFIRM", "NAVIGATE", "ALERT"]),
    ...mapActions("API", ["API"]),

    open(){
      this.c1 = null;
      this.c2 = null;
      this.customer_1 = null;
      this.customer_2 = null;
      this.name = null;
      this.show = true;
    },

    fetchCustomer1(code){
      this.customer_1 = null;
      if(!code) return;
      this.loading = true;
      this.API({ method: "GET", endpoint: `customers/${code}` })
      .then(customer => {
        this.customer_1 = customer;
        this.name = this.customer_1.name;
      })
      .finally(() => { this.loading = false; })
    },

    fetchCustomer2(code){
      this.customer_2 = null;
      if(!code) return;
      this.loading = true;
      this.API({ method: "GET", endpoint: `customers/${code}` })
      .then(customer => {
        this.customer_2 = customer;
      })
      .finally(() => { this.loading = false; })
    },

    merge(){
      this.CONFIRM({ title: "Confirm Merge", message: "This action can not be undone." })
      .then(() => {
        let data = {
          customer_1: this.customer_1.xid,
          customer_2: this.customer_2.xid,
          name: this.name
        };
        this.API({ method: "POST", endpoint: "customers/merge", data })
        .then(() => {
          this.ALERT({ title: "Merge Customers", message: "Merge successful" });
          this.NAVIGATE(`/dash/crm/${this.customer_1.xid}`);
        });
      });
    }

  },
  created(){}
}
</script>
