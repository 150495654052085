<template>
  <div>
    <v-card class='mx-3 pt-1 transparent' elevation=0 style='max-width: 1000px;'>
      <v-container>

        <v-card>
          <v-card-text>
            <v-list>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class=''>Merge Customers</v-list-item-title>
                  <v-list-item-subtitle>Merge all jobs, notes, addresses &amp; contacts from two customers into a single customer file.</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn class='primary' @click='()=>{ $refs.customer_merge.open() }'><v-icon class='mr-2'>mdi-merge</v-icon>Merge</v-btn>
                </v-list-item-action>
                <CustomerMerge ref="customer_merge" />
              </v-list-item>

              <!--<v-divider></v-divider>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class=''>Taskflow Presets</v-list-item-title>
                  <v-list-item-subtitle>Create taskflows that can be quick-selected in job cards.</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn class='primary' @click='()=>{ $refs.taskflow_list.open() }'><v-icon class='mr-2'>mdi-view-list</v-icon>Taskflows</v-btn>
                </v-list-item-action>
              </v-list-item>-->

            </v-list>
          </v-card-text>
        </v-card>

      </v-container>
    </v-card>

  </div>
</template>

<style lang="scss">
</style>


<script>
import { mapActions } from 'vuex';
import Deferred from '@/helpers/Deferred'
//import ListModal from '@/components/Common/ListModal'
import CustomerMerge from '@/components/Customer/CustomerMerge'

export default {
  name: 'About',
  components: {
    CustomerMerge
  },
  watch:{
    $route: { handler(){ let t = `Customer Settings`; this.SET_TITLE(t); }, immediate: true },
  },
  methods: {
    ...mapActions("UI", ['SET_TITLE', 'CONFIRM']),
    addTask(){
      return this.CONFIRM({ title: 'Confirm?', message: 'Testing!' });
    },

    loadTasks(){
      let { promise, resolve } = new Deferred;
      resolve([
        { xid: 1, text: "Hello" },
        { xid: 2, text: "Hello again." },
        { xid: 3, text: "Hello again and again and again and again and again and again." },
        { xid: 4, text: "Hello a fourth time." },
      ]);
      return promise;
    },
    editTask(item){
      console.log("EDIT:", item);
      this.$refs.task_list.refresh();
    },
    deleteTask(item){
      console.log("DELETE:", item);
      this.$refs.task_list.refresh();
    },
    sortTasks(sort_order){
      console.log("SORT", sort_order);
    },

  },
  mounted(){
  }
}
</script>
