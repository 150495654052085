var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "value": _vm.show,
      "persistent": "",
      "max-width": "500px",
      "scrollable": ""
    }
  }, [_c('v-card', {
    attrs: {
      "disabled": _vm.loading,
      "loading": _vm.loading
    }
  }, [_c('v-card-title', {
    staticClass: "primary white--text py-2"
  }, [_vm._v(" Merge Customers ")]), _c('v-card-text', {
    staticStyle: {
      "max-height": "500px"
    }
  }, [_c('v-row', {
    staticClass: "mt-2"
  }, [_c('v-col', {
    staticClass: "secondary--text",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" Customer ID can be found in the address bar of a customer card."), _c('br'), _c('span', {
    staticClass: "font-italic"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("eg.")]), _vm._v(" https://my.mountglass.com.au/dash/crm/"), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v("abc123xyz")])])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "Customer ID"
    },
    on: {
      "change": _vm.fetchCustomer1
    },
    model: {
      value: _vm.c1,
      callback: function callback($$v) {
        _vm.c1 = $$v;
      },
      expression: "c1"
    }
  }), _vm.customer_1 ? _c('div', {
    staticClass: "text-subtitle-2 text-center mt-1"
  }, [_vm._v(_vm._s(_vm.customer_1.name))]) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "Customer ID"
    },
    on: {
      "change": _vm.fetchCustomer2
    },
    model: {
      value: _vm.c2,
      callback: function callback($$v) {
        _vm.c2 = $$v;
      },
      expression: "c2"
    }
  }), _vm.customer_2 ? _c('div', {
    staticClass: "text-subtitle-2 text-center mt-1"
  }, [_vm._v(_vm._s(_vm.customer_2.name))]) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "New Customer Name"
    },
    model: {
      value: _vm.name,
      callback: function callback($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "secondary",
    on: {
      "click": function click($event) {
        _vm.show = false;
      }
    }
  }, [_vm._v("Cancel")]), _c('v-btn', {
    staticClass: "primary",
    attrs: {
      "disabled": !_vm.valid
    },
    on: {
      "click": _vm.merge
    }
  }, [_vm._v("Merge")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }