var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card', {
    staticClass: "mx-3 pt-1 transparent",
    staticStyle: {
      "max-width": "1000px"
    },
    attrs: {
      "elevation": "0"
    }
  }, [_c('v-container', [_c('v-card', [_c('v-card-text', [_c('v-list', [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {}, [_vm._v("Merge Customers")]), _c('v-list-item-subtitle', [_vm._v("Merge all jobs, notes, addresses & contacts from two customers into a single customer file.")])], 1), _c('v-list-item-action', [_c('v-btn', {
    staticClass: "primary",
    on: {
      "click": function click() {
        _vm.$refs.customer_merge.open();
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v("mdi-merge")]), _vm._v("Merge")], 1)], 1), _c('CustomerMerge', {
    ref: "customer_merge"
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }